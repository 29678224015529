/* eslint-env browser */
/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AUBachelorFilter from './AUBachelorFilter';
import actionCreators from '../actions/bachelor';
import { useFilters, emptyFilter, langContext } from '../helpers/hooks';
import { labels } from '../i18n';

const arrayContainsOnlyOneUniqueValue = (arr) => {
  const set = [...new Set(arr)];
  return set.length === 1;
};

const AUBachelorModal = ({
  modal,
  interest,
  languages,
  locations,
  globalFilters,
  setGlobalFilters,
  filteredItems,
}) => {
  const lang = useContext(langContext);
  const [onlineOnly, setOnlineOnly] = useState(false);
  const [filters, setFilters, renderCollection] = useFilters(globalFilters, setGlobalFilters);
  const { Name = '', Items = [] } = modal || {};

  let filteredItemsWithOnlineCheck = filteredItems || [];
  if (onlineOnly) {
    filteredItemsWithOnlineCheck = filteredItemsWithOnlineCheck.filter((item) => !!item.Online);
  }

  const locationUnique = arrayContainsOnlyOneUniqueValue(Items.map((x) => x.Location));
  const languageUnique = arrayContainsOnlyOneUniqueValue(Items.map((x) => x.Language));
  const onlineUnique = arrayContainsOnlyOneUniqueValue(Items.map((x) => !!x.Online));

  const renderEducations = filteredItemsWithOnlineCheck.filter((item) => item.Parent === 0).map((education) => (
    <a key={education.ID} href={education.Uri}>
      <div className="bachelor__education">
        {
          !!education.Online && (
            <span className="bachelor__education__note">{education.Online}</span>
          )
        }
        {
          education.Note && (
            <span className="bachelor__education__note">{education.Note}</span>
          )
        }
        <h4 className="bachelor__education__header">{education.Name}</h4>
        <p className="bachelor__education__description">
          {education.Description}
        </p>
        {
          education.Children.length > 0 && (
            <>
              <h5>{labels.childrenHeader[lang]}</h5>
              <ul className="bachelor__education__children">
                {education.Children.map((child) => <li key={child.ID}><a href={child.Uri}>{child.Name}</a></li>)}
              </ul>
            </>
          )
        }
        <div className="bachelor__education__info">
          <dl className="bachelor__education__info__group">
            <dt>{`${labels.filters.location[lang]}:`}</dt>
            <dd>{education.Location}</dd>
          </dl>
          <dl className="bachelor__education__info__group">
            <dt>{`${labels.filters.language[lang]}:`}</dt>
            <dd>{education.Language}</dd>
          </dl>
        </div>
      </div>
    </a>
  ));

  return (
    <div className="modal-view__body">
      <div className="bachelor__education__interest-container">
        <h3 className={`bachelor__education__interest-container__header bachelor__education__interest-container__header--${interest}`}>{Name}</h3>
        {
          !(locationUnique && languageUnique && onlineUnique) && (
            <div className="bachelor__education__filter__container bachelor__education__filter__container--modal">
              <span>{`${labels.addFilter[lang]}:`}</span>
              {
                !languageUnique && (
                  <AUBachelorFilter
                    visible={filters.languages}
                    selected={globalFilters.languages.length > 0}
                    show={() => {
                      setFilters({
                        languages: true,
                        locations: false,
                      });
                    }}
                    hide={() => {
                      setFilters(emptyFilter);
                    }}
                    clear={() => {
                      setGlobalFilters({ ...globalFilters, languages: [] });
                    }}
                    filterName={globalFilters.languages[0]?.Name || labels.filters.language[lang]}
                    filterLabel={`${labels.select[lang]} ${labels.filters.language[lang].toLowerCase()}`}
                    renderItems={renderCollection(languages, 'languages')}
                  />
                )
              }
              {
                !locationUnique && (
                  <AUBachelorFilter
                    visible={filters.locations}
                    selected={globalFilters.locations.length > 0}
                    show={() => {
                      setFilters({
                        languages: false,
                        locations: true,
                      });
                    }}
                    hide={() => {
                      setFilters(emptyFilter);
                    }}
                    clear={() => {
                      setGlobalFilters({ ...globalFilters, locations: [] });
                    }}
                    filterName={globalFilters.locations[0]?.Name || labels.filters.location[lang]}
                    filterLabel={`${labels.select[lang]} ${labels.filters.location[lang].toLowerCase()}`}
                    renderItems={renderCollection(locations, 'locations')}
                  />
                )
              }
              {
                !onlineUnique && (
                  <div className="bachelor__education__filter">
                    <button
                      type="button"
                      className={`bachelor__education__filter__button${onlineOnly ? ' bachelor__education__filter__button--selected' : ''}`}
                      onClick={() => setOnlineOnly(!onlineOnly)}
                    >
                      {labels.filters.online[lang]}
                    </button>
                  </div>
                )
              }
            </div>
          )
        }
        {
          filteredItemsWithOnlineCheck.filter((item) => item.Parent === 0).length > 0 && (
            <div className="bachelor__education__container bachelor__education__container--modal">
              {renderEducations}
            </div>
          )
        }
        {
          filteredItemsWithOnlineCheck.filter((item) => item.Parent === 0).length === 0 && (
            <div className="bachelor__education__no-results">
              <div className="bachelor__education__no-results__text">{labels.noResults[lang]}</div>
            </div>
          )
        }
        <a href={window.baOverviewUri || '/'} className="button bg-magenta bachelor__education__link-to-all">{labels.linkToAll[lang]}</a>
      </div>
    </div>
  );
};

AUBachelorModal.propTypes = {
  modal: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Items: PropTypes.arrayOf(PropTypes.shape({

    })).isRequired,
  }).isRequired,
  interest: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape({

  })).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({

  })).isRequired,
  globalFilters: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  setGlobalFilters: PropTypes.func.isRequired,
  filteredItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

AUBachelorModal.displayName = 'AUBachelorModal';

export default connect(
  (state) => state.bachelor,
  (dispatch) => bindActionCreators(actionCreators, dispatch),
)(AUBachelorModal);
